<template>
  <q-drawer
    v-model="ui.quasar.sides.left"
    :breakpoint="ui.quasar.breakpoints.left"
    :mini="ui.quasar.mini.mode"
    :mini-width="ui.quasar.mini.width"
    elevated
    show-if-above
    side="left"
    animated
  >
    <q-scroll-area class="fit">
      <q-list padding>
        <q-item-label v-if="hasRole('middleoffice')" header>
          My Mobilleo
        </q-item-label>
        <q-item v-ripple clickable to="/dashboard" tag="a">
          <q-item-section avatar>
            <q-icon name="dashboard" color="blue-8" />
          </q-item-section>
          <q-item-section>Dashboard</q-item-section>
          <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
            Dashboard
          </q-tooltip>
        </q-item>
        <q-item v-ripple clickable to="/smart-search" tag="a">
          <q-item-section avatar>
            <m-image :src="require('assets/geo.png')" width="24px" />
          </q-item-section>
          <q-item-section>Smart Search</q-item-section>
          <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
            Smart Search
          </q-tooltip>
        </q-item>
      </q-list>
      <template v-if="hasRole(['finance', 'admin'])">
        <q-separator style="margin: 0" />
        <q-list padding>
          <q-item-label header>
            Finance
          </q-item-label>
          <q-item v-ripple clickable to="/finance/export/finance/" tag="a">
            <q-item-section avatar>
              <q-icon name="cloud_download" color="deep-purple-8" />
            </q-item-section>
            <q-item-section>CSV Exports</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Exports
            </q-tooltip>
          </q-item>
        </q-list>
      </template>
      <template v-if="hasRole('middleoffice')">
        <q-separator style="margin: 0" />
        <q-list padding>
          <q-item-label header>
            Organisation
          </q-item-label>
          <q-item v-ripple clickable to="/members" tag="a">
            <q-item-section avatar>
              <q-icon name="supervisor_account" color="deep-purple-8" />
            </q-item-section>
            <q-item-section>Members</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Members
            </q-tooltip>
          </q-item>
          <q-item
            v-ripple
            clickable
            :to="{ name: 'organisation-bookings' }"
            tag="a"
          >
            <q-item-section avatar>
              <q-icon name="receipt" color="deep-purple-8" />
            </q-item-section>
            <q-item-section>Bookings</q-item-section>
            <q-tooltip :disable="!ui.quasar.mini.mode" v-bind="tooltip">
              Bookings
            </q-tooltip>
          </q-item>
          <q-item v-ripple clickable to="/organisation/journeys" tag="a">
            <q-item-section avatar>
              <q-icon name="map" color="deep-purple-8" />
            </q-item-section>
            <q-item-section>Journeys</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Journeys
            </q-tooltip>
          </q-item>
          <q-item
            v-ripple
            clickable
            :to="{ name: 'organisation-invoices' }"
            tag="a"
          >
            <q-item-section avatar>
              <q-icon name="library_books" color="deep-purple-8" />
            </q-item-section>
            <q-item-section>Invoices</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Invoices
            </q-tooltip>
          </q-item>
        </q-list>
      </template>
      <!-- Customers/Systems/OnDemand - roles [admin, backoffice] -->
      <template v-if="hasRole(['admin', 'backoffice'])">
        <q-separator style="margin: 0" />
        <q-list padding>
          <q-item-label header>
            Customers
          </q-item-label>
          <q-item v-ripple clickable to="/individuals" tag="a">
            <q-item-section avatar>
              <q-icon name="person" color="green-9" />
            </q-item-section>
            <q-item-section>Individuals</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Individuals
            </q-tooltip>
          </q-item>
          <q-item v-ripple clickable to="/organisations" tag="a">
            <q-item-section avatar>
              <q-icon name="group" color="orange-9" />
            </q-item-section>
            <q-item-section>Organisations</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Organisations
            </q-tooltip>
          </q-item>
          <q-item v-ripple clickable to="/requests" tag="a">
            <q-item-section avatar>
              <q-icon name="bookmark_border" color="purple" />
            </q-item-section>
            <q-item-section>Requests</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Requests
            </q-tooltip>
          </q-item>
          <q-item v-ripple clickable to="/journeys" tag="a">
            <q-item-section avatar>
              <q-icon name="map" color="orange-5" />
            </q-item-section>
            <q-item-section>Journeys</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Journeys
            </q-tooltip>
          </q-item>
          <q-item v-ripple clickable to="/bookings" tag="a">
            <q-item-section avatar>
              <q-icon name="receipt" color="brown" />
            </q-item-section>
            <q-item-section>Bookings</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Bookings
            </q-tooltip>
          </q-item>
          <q-item v-ripple clickable to="/invoices" tag="a">
            <q-item-section avatar>
              <q-icon name="library_books" color="brown" />
            </q-item-section>
            <q-item-section>Invoices</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Invoices
            </q-tooltip>
          </q-item>
        </q-list>
        <q-separator style="margin: 0" />
        <q-list padding>
          <q-item-label header>
            System
          </q-item-label>
          <q-item v-ripple clickable to="/sdc" tag="a">
            <q-item-section avatar>
              <q-icon name="group_work" color="purple-6" />
            </q-item-section>
            <q-item-section>SDC</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              SDC
            </q-tooltip>
          </q-item>

          <q-item v-if="hasRole('admin')" v-ripple clickable to="/partner/reports" tag="a">
            <q-item-section avatar>
              <q-icon name="fas fa-sitemap" color="primary" />
            </q-item-section>
            <q-item-section>Reports</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Reports
            </q-tooltip>
          </q-item>
        </q-list>
        <q-separator style="margin: 0" />
        <q-list padding>
          <q-item-label header>
            On demand
          </q-item-label>
          <q-item
            v-if="hasPermission('search.rides')"
            v-ripple
            clickable
            to="/on-demand/office/ride-hailing"
            tag="a"
          >
            <q-item-section avatar>
              <q-icon name="local_taxi" color="black" />
            </q-item-section>
            <q-item-section>Taxi</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Taxi
            </q-tooltip>
          </q-item>
          <q-item
            v-if="hasPermission('search.onwardtravel') || hasRole('backoffice')"
            v-ripple
            clickable
            to="/on-demand/onwardtravel"
            tag="a"
          >
            <q-item-section avatar>
              <q-icon name="local_taxi" color="pink" />
            </q-item-section>
            <q-item-section>Onward</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Onward
            </q-tooltip>
          </q-item>
          <q-item v-ripple clickable to="/on-demand/trains" tag="a">
            <q-item-section avatar>
              <q-icon name="train" color="blue-8" />
            </q-item-section>
            <q-item-section>Trains</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Trains
            </q-tooltip>
          </q-item>
          <q-item v-ripple clickable to="/on-demand/hotel" tag="a">
            <q-item-section avatar>
              <q-icon name="hotel" color="light-green" />
            </q-item-section>
            <q-item-section>Hotel</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Hotel
            </q-tooltip>
          </q-item>
          <q-item v-ripple clickable to="/on-demand/car-hire" tag="a">
            <q-item-section avatar>
              <q-icon name="directions_car" color="lime-5" />
            </q-item-section>
            <q-item-section>Car rental</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Car rental
            </q-tooltip>
          </q-item>
          <q-item v-ripple clickable to="/on-demand/flights" tag="a">
            <q-item-section avatar>
              <q-icon name="flight" color="red" />
            </q-item-section>
            <q-item-section>Flights</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Flights
            </q-tooltip>
          </q-item>
        </q-list>
      </template>
      <!-- On demand - NOT role [admin, backoffice] -->
      <template v-if="!hasRole(['admin', 'backoffice'])">
        <q-separator />
        <q-list padding>
          <q-item-label header>
            On demand
          </q-item-label>
          <q-item
            v-if="hasPermission('search.rentals')"
            v-ripple
            clickable
            to="/on-demand/car-hire"
            tag="a"
          >
            <q-item-section avatar>
              <q-icon name="directions_car" color="lime-5" />
            </q-item-section>
            <q-item-section>Car rental</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Car rental
            </q-tooltip>
          </q-item>
          <q-item
            v-if="hasPermission('search.flights')"
            v-ripple
            clickable
            to="/on-demand/flights"
            tag="a"
          >
            <q-item-section avatar>
              <q-icon name="flight" color="red" />
            </q-item-section>
            <q-item-section>Flights</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Flights
            </q-tooltip>
          </q-item>
          <q-item
            v-if="hasPermission('search.trains')"
            v-ripple
            clickable
            to="/on-demand/trains"
            tag="a"
          >
            <q-item-section avatar>
              <q-icon name="train" color="blue-8" />
            </q-item-section>
            <q-item-section>Trains</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Trains
            </q-tooltip>
          </q-item>
          <q-item
            v-if="hasPermission('search.rides') && !hasRole(['middleoffice'])"
            v-ripple
            clickable
            to="/on-demand/ride-hailing"
            tag="a"
          >
            <q-item-section avatar>
              <q-icon name="local_taxi" color="black" />
            </q-item-section>
            <q-item-section>Taxi</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Taxi
            </q-tooltip>
          </q-item>
          <q-item
            v-if="hasPermission('search.rides') && hasRole(['middleoffice'])"
            v-ripple
            clickable
            to="/on-demand/office/ride-hailing"
            tag="a"
          >
            <q-item-section avatar>
              <q-icon name="local_taxi" color="black" />
            </q-item-section>
            <q-item-section>Taxi</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Taxi
            </q-tooltip>
          </q-item>
          <q-item
            v-if="hasPermission('search.hotels')"
            v-ripple
            clickable
            to="/on-demand/hotel"
            tag="a"
          >
            <q-item-section avatar>
              <q-icon name="hotel" color="light-green" />
            </q-item-section>
            <q-item-section>Hotel</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Hotel
            </q-tooltip>
          </q-item>
          <q-item
            v-if="showCarClub"
            v-ripple
            clickable
            to="/on-demand/carclub"
            tag="a"
          >
            <q-item-section avatar>
              <q-icon name="directions_car" color="green-8" />
            </q-item-section>
            <q-item-section>Car club</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Car club
            </q-tooltip>
          </q-item>
          <q-item
            v-if="hasPermission('search.bus')"
            v-ripple
            clickable
            to="/on-demand/bus"
            tag="a"
          >
            <q-item-section avatar>
              <q-icon name="directions_bus" color="deep-purple-5" />
            </q-item-section>
            <q-item-section>Bus</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Bus
            </q-tooltip>
          </q-item>
          <q-item
            v-if="hasPermission('search.tram')"
            v-ripple
            clickable
            to="/on-demand/tram"
            tag="a"
          >
            <q-item-section avatar>
              <q-icon name="tram" color="indigo-4" />
            </q-item-section>
            <q-item-section>Tram</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Tram
            </q-tooltip>
          </q-item>
        </q-list>
      </template>
      <template v-if="!hasRole(['admin', 'backoffice'])">
        <q-separator />
        <q-list padding>
          <q-item-label header>
            Expenses
          </q-item-label>
          <q-item v-ripple clickable to="/expenses" tag="a">
            <q-item-section avatar>
              <q-icon name="mdi-currency-gbp" color="yellow-14" />
            </q-item-section>
            <q-item-section>Expenses</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Expenses
            </q-tooltip>
          </q-item>
        </q-list>
      </template>
      <template v-if="hasRole('middleoffice')">
        <q-separator />
        <q-list padding>
          <q-item-label header>
            Intelligence
          </q-item-label>
          <q-item
            v-ripple
            clickable
            to="/reports/intelligence/reporting"
            tag="a"
          >
            <q-item-section avatar>
              <q-icon name="show_chart" color="deep-purple" />
            </q-item-section>
            <q-item-section>Reporting</q-item-section>
            <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
              Reporting
            </q-tooltip>
          </q-item>
        </q-list>
      </template>
      <q-separator />
      <q-list padding>
        <q-item-label header>
          Discovery
        </q-item-label>
        <q-item v-ripple clickable to="/discovery/coffee-shop" tag="a">
          <q-item-section avatar>
            <q-icon name="local_cafe" color="red-8" />
          </q-item-section>
          <q-item-section>Coffee shops</q-item-section>
          <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
            Coffee shops
          </q-tooltip>
        </q-item>
        <q-item v-ripple clickable to="/discovery/restaurants" tag="a">
          <q-item-section avatar>
            <q-icon name="restaurant" color="grey-8" />
          </q-item-section>
          <q-item-section>Restaurants</q-item-section>
          <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
            Restaurants
          </q-tooltip>
        </q-item>
        <q-item v-ripple clickable to="/discovery/ev-station" tag="a">
          <q-item-section avatar>
            <q-icon name="ev_station" color="blue-grey-3" />
          </q-item-section>
          <q-item-section>Ev stations</q-item-section>
          <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
            Ev stations
          </q-tooltip>
        </q-item>
        <q-item v-ripple clickable to="/discovery/petrol-station" tag="a">
          <q-item-section avatar>
            <q-icon name="local_gas_station" color="yellow-8" />
          </q-item-section>
          <q-item-section>Petrol stations</q-item-section>
          <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
            Petrol stations
          </q-tooltip>
        </q-item>
        <q-item v-ripple clickable to="/discovery/cash-machine" tag="a">
          <q-item-section avatar>
            <q-icon name="mdi-cash" color="green-10" />
          </q-item-section>
          <q-item-section>Cash machines</q-item-section>
          <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
            Cash machines
          </q-tooltip>
        </q-item>
        <q-item v-ripple clickable to="/discovery/parking" tag="a">
          <q-item-section avatar>
            <q-icon name="local_parking" color="blue" />
          </q-item-section>
          <q-item-section>Parking</q-item-section>
          <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
            Parking
          </q-tooltip>
        </q-item>
      </q-list>
      <q-separator />
      <q-list padding>
        <q-item
          v-ripple
          clickable
          href="https://mobilleo.com/resources/support/"
          tag="a"
          target="_blank"
        >
          <q-item-section avatar>
            <q-icon name="feedback" color="yellow-8" />
          </q-item-section>
          <q-item-section>Feedback</q-item-section>
          <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
            Send feedback
          </q-tooltip>
        </q-item>
        <q-item
          v-ripple
          clickable
          href="https://support.mobilleo.com"
          tag="a"
          target="_blank"
        >
          <q-item-section avatar>
            <q-icon name="help" color="blue-8" />
          </q-item-section>
          <q-item-section>Help</q-item-section>
          <q-tooltip v-if="ui.quasar.mini.mode" v-bind="tooltip">
            Help
          </q-tooltip>
        </q-item>
      </q-list>
      <small class="q-mini-drawer-hide q-mx-md q-my-sm text-faded">
        <a
          target="_blank"
          href="https://www.fodmobilitygroup.com/wp-content/uploads/2023/02/FOD-Mobility-Group-Privacy-Policy-23.pdf"
        >
          Privacy
        </a>
        &nbsp;&middot;&nbsp;
        <a
          target="_blank"
          href="https://mobilleo.s3.eu-west-2.amazonaws.com/terms.pdf"
        >
          Terms
        </a>
      </small>
    </q-scroll-area>
  </q-drawer>
</template>

<script>
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'
import { MImage } from 'components/'

export default {
  name: 'LayoutBasicSidebar',
  components: { MImage },
  mixins: [authentication],
  data () {
    return {
      tooltip: {
        offset: [-64, 0],
        anchor: 'top left',
        self: 'top left',
        delay: 300
      }
    }
  },
  computed: {
    ...mapGetters(['ui']),
    showCarClub () {
      return this.hasRole('feature.carclub') && this.hasPermission('search.carclub')
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-item
  min-height initial

  .q-item__section
    min-width initial
    color initial
</style>
